/**
 * 车主用钱/车主服务
 */

export const rCarOwner = [
  {
    path: "/usingMoney",
    name: "UsingMoney",
    meta: { title: "车主用钱", hideHeader: true, hideDownload: true },
    component: () => import("@/views/usingMoney/UsingMoney.vue")
  },
  {
    path: "/service",
    name: "Service",
    meta: { title: "车主服务", hideHeader: true },
    component: () => import("@/views/service/Service.vue")
  },
  {
    path: "/calculator",
    name: "Calculator",
    meta: { title: "计算器" },
    component: () => import("@/views/service/Calculator.vue")
  },
  {
    path: "/calculatorResult",
    name: "CalculatorResult",
    meta: { title: "计算结果" },
    component: () => import("@/views/service/CalculatorResult.vue")
  }
];
