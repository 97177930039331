/**
 * H5公共页面
 */

export const rHome = [
  {
    path: "/download",
    name: "Download",
    meta: { title: "APP下载引导", hideHeader: true, hideDownload: true },
    component: () => import("@/views/downloadPage/downloadPage.vue")
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    meta: { title: "隐私政策", hideHeader: true, hideDownload: true },
    component: () => import("@/views/policy/privacyPolicy.vue")
  },
  {
    path: "/privacySDK",
    name: "privacySDK",
    meta: { title: "隐私政策", hideHeader: true, hideDownload: true },
    component: () => import("@/views/policy/shareSdkPrivacy.vue")
  }
];
