<template>
  <div id="app">
    <!-- APP 下载导航 48px -->
    <app-download v-if="hasDownLoad" />
    <!-- 公共 Header 44px -->
    <Header v-show="isShowHeader" :class="{ headerTotop: isShowDownLoad }" :title="routerMsg.title" :hideBorder="routerMsg.hideBorder" :preRouterName="routerMsg.preRouterName" />
    <router-view :style="`padding-top:${padTop}rem`" />
  </div>
</template>

<script>
import AppDownload from "@/components/AppDownload.vue";
import Header from "@/components/Header.vue";
import { mapState } from "vuex";
export default {
  data () {
    return {};
  },
  created () {
    // 次日首次打开需展示 APP 下载引导
    if (!this.isShowDownLoad) {
      const lastTime = this.$tools.formatDate(this.closeTimeStamp);
      if (this.$tools.formatDate(new Date()) != lastTime) {
        this.$store.commit("setAPPStatus", true);
      }
    }
  },
  mounted () {
    window.addEventListener("pagehide", () => this.pagehide());
  },
  methods: {
    pagehide () {
      this.$store.commit("setLoginInfo", {});
    }
  },
  destroyed () {
    window.removeEventListener("pagehide", () => this.pagehide());
  },
  computed: {
    ...mapState(["routerMsg", "isShowDownLoad", "closeTimeStamp"]),
    // 是否显示header
    isShowHeader () {
      return !this.routerMsg.hideHeader;
    },
    // 是否展示下载引导
    hasDownLoad () {
      return this.isShowDownLoad && !this.routerMsg.hideDownload;
    },
    // 计算固定定位高度
    padTop () {
      const a = this.isShowHeader ? 0.92 : 0.48;
      const b = this.isShowHeader ? 0.44 : 0;
      return this.hasDownLoad ? a : b;
    }
  },
  components: { AppDownload, Header }
};
</script>

<style lang="scss">
#app {
  font-family: PingFangSC-Regular;
  font-size: 0.14rem;
  height: 100%;
  color: $mainBlack;
}
.headerTotop {
  top: 0.48rem !important;
}
</style>
