import Vue from "vue";
import {
  Button,
  Toast,
  List,
  Overlay,
  Popup,
  Switch,
  Field,
  Picker,
  Dialog,
  Tag,
  Lazyload,
  Swipe,
  SwipeItem,
  Image as VanImage,
  IndexBar,
  IndexAnchor,
  Cell,
  NavBar,
  DatetimePicker,
  ActionSheet,
  Search,
  Icon,
  Col,
  Row,
  ImagePreview,
  PullRefresh
} from "vant";
Vue.use(Button);
Vue.use(Toast);
Vue.use(List);
Vue.use(Overlay);
Vue.use(Popup);
Vue.use(Switch);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Lazyload);
Vue.use(Tag);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Cell);
Vue.use(NavBar);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(PullRefresh);
Vue.prototype.$ImagePreview = ImagePreview;
