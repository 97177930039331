import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/utils/vant.js";
import "@/assets/css/reset.css";
import "@/utils/auto.js";
import yxPath from "@/utils/yxPath.js";
import tools from "@/utils/tools";
Vue.prototype.$yxPath = yxPath;
Vue.prototype.$tools = tools;
import Geolocation from "@/utils/geolocation";
Vue.prototype.$Geolocation = Geolocation;

import Footer from "@/components/Footer";
Vue.component("Footer", Footer);
import Header from "@/components/Header";
Vue.component("Header", Header);
// import Vconsole from "vconsole";
// new Vconsole();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
