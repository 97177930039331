/**
 * 首页以及部分首页子页面
 */

export const rHome = [
  {
    path: "/login",
    name: "Login",
    meta: { title: "登录", hideHeader: true, hideDownload: true },
    component: () => import("@/views/login/Login.vue")
  },
  {
    path: "/",
    name: "Home",
    meta: { title: "首页", hideHeader: true },
    component: () => import("@/views/home/Home.vue")
  },
  {
    path: "/citySelect",
    name: "CitySelect",
    meta: { title: "选择城市" },
    component: () => import("@/views/home/CitySelect.vue")
  },
  {
    path: "/searchResult",
    name: "SearchResult",
    meta: { title: "品牌搜索", hideHeader: true },
    component: () => import("@/views/home/SearchResult.vue")
  },
  {
    path: "/bankList",
    name: "BankList",
    meta: { title: "全部资方" },
    component: () => import("@/views/banks/BankList.vue")
  },
  {
    path: "/bankDetail",
    name: "BankDetail",
    meta: { title: "", hideBorder: true },
    component: () => import("@/views/banks/BankDetail.vue")
  }
];
