/**
 * 首页
 */

import axios from "./axios";

// 获取首页焦点图
export function getBannerList(params) {
  return axios.post("/lyra-middleground/api/advertising/m/adList", params);
}
// 查询城市id
export function queryCityId(params) {
  return axios.post("/lyra-middleground/api/search/queryCityId", params);
}
// 首页搜索接口(车系)
export function searchSerial(params) {
  return axios.post("/lyra-middleground/api/search/searchSerial", params);
}

// 获取热门车型列表
export function getHotCarList(params) {
  return axios.post("/lyra-middleground/api/home/getHotCarList", params);
}

// 资方列表
export function funderList(params) {
  return axios.post("/lyra-middleground/api/funder/funderList", params);
}
// 资方详情
export function funderInfo(params) {
  return axios.post("/lyra-middleground/api/funder/funderInfo", params);
}

// 城市/品牌列表选择接口
export function getCityAndValidityList(params) {
  return axios.post(
    "/lyra-middleground/api/search/getCityAndValidityList",
    params
  );
}
