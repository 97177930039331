import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store/index";

Vue.use(VueRouter);

// 自动加载router文件夹下的所有js文件
const context = require.context("@/router", false, /\.js$/);
let routesConfig = [];
context.keys().forEach(v => {
  if (v === "./index.js") {
    return;
  }
  const obj = context(v);
  routesConfig = routesConfig.concat(obj[Object.keys(obj)[0]]);
});

routesConfig.push({
  path: "*",
  name: "404",
  redirect: "/"
});

const router = new VueRouter({
  mode: "history",
  routes: routesConfig,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

/**
 * 存储即将跳转页面的 meta 信息，meta 信息说明：
 * title：配置页面 header 标题
 * preRouterName：配置点击 header 返回跳转的页面
 * hideHeader：配置是否展示 header
 * hideDownload：配置是否展示 APP 下载引导
 */
router.beforeEach((to, from, next) => {
  // 设置分期购车详情返回跳转页面（禁止返回选择车型列表）
  if (
    to.name === "CarDetail" &&
    (from.name === "CarList" || from.name === "Home")
  ) {
    to.meta.preRouterName = from.name;
  }
  //  else if (from.name === "Login" && from.params.name === "BUY_ADVISORY") {
  //   next("BuyAdvisory");
  // }
  Store.commit("setRouterMsg", to.meta);
  next();
});

export default router;
