let yxPath = {};

if (location.host.includes("m.daikuan.com")) {
  yxPath = {
    carOwnerUsingMoney:
      "https://yxapp.yixincapital.com/#/newMarketing?newMarketingType=1&thirdPartyId=yixinjinrongMzhan&timestamp=1654063457022&accessToken=5e5d08ec343cd18c3cdcf889a963ac8c&subThirdPartyId=220601menunew&timer=1654063457022"
  };
} else {
  yxPath = {
    carOwnerUsingMoney:
      "https://yxapp.uat.yixincapital.com/#/marketingCenter?thirdPartyId=yixinjinrongMzhan&timestamp=1618293951183&accessToken=86b33a2f80e81c50b0124b54560addf4&subThirdPartyId=chezhurong"
  };
}

export default yxPath;
