import Vue from "vue";
import axios from "axios";
import Store from "@/store/index";
import Route from "@/router/index";

axios.defaults.timeout = 20000;
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8"; // 配置请求头

// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (!config.hideLoading) {
      Vue.prototype.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.data) {
      if (response.data.code !== 0 && response.data.msg) {
        // Vue.prototype.$toast(response.data.msg);
        //时间长一点
        Vue.prototype.$toast({
          message: response.data.msg,
          duration: 1500
        });
      }
      if (!response.config.hideLoading) {
        Vue.prototype.$toast.clear();
      }
      return Promise.resolve(response.data);
    }
  },
  error => {
    // token 失效
    if (error.response && error.response.status == 408) {
      Store.commit("setLoginInfo", "");
      Route.replace({ name: "Login" });
    }
    Vue.prototype.$toast.clear();
    return Promise.reject(error);
  }
);

export default axios;
