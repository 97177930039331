<!-- 底部菜单 -->
<template>
  <div class="Footer">
    <div class="item" v-for="(item, index) in iconList" :key="index" @click="tabChange(item.routerName, index)">
      <img class="icon" :src="activeKey === index ? item.src : item.src2" />
      <p :class="{ active: activeKey === index }">{{ item.name }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  props: {
    active: { type: Number }
  },
  data () {
    return {
      activeKey: this.active,
      iconList: [
        {
          name: "首页",
          src: require("@/assets/images/footer/1_1.png"),
          src2: require("@/assets/images/footer/1_2.png"),
          routerName: "Home"
        },
        {
          name: "车主用钱",
          src: require("@/assets/images/footer/2_1.png"),
          src2: require("@/assets/images/footer/2_2.png"),
          routerName: "UsingMoney"
        },
        {
          name: "车主服务",
          src: require("@/assets/images/footer/3_1.png"),
          src2: require("@/assets/images/footer/3_2.png"),
          routerName: "Service"
        },
        {
          name: "我的",
          src: require("@/assets/images/footer/4_1.png"),
          src2: require("@/assets/images/footer/4_2.png"),
          routerName: "Personal"
        }
      ]
    };
  },
  computed: {
    ...mapState(["loginInfo"])
  },
  methods: {
    // 底部菜单切换
    tabChange (routerName, index) {
      if (this.activeKey === index) {
        return;
      }
      //如果跳转的是用户用钱,并且没有登陆跳转登陆
      if (routerName === "UsingMoney" && !this.loginInfo.userId) {
        this.$router.push({
          name: "Login",
          params: {
            name: "USING_MONEY"
          }
        });
        return;
      }
      this.activeKey = index;
      this.$router.replace({ name: routerName });
    }
  }
};
</script>
<style lang="scss" scoped>
.Footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 0.5rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .item {
    font-size: 0.1rem;
    color: $mainBlack;
    text-align: center;
    .icon {
      width: 0.24rem;
      height: 0.24rem;
      margin-bottom: 0.04rem;
    }
    .active {
      color: $mainRed;
    }
  }
}
</style>
