/**
 * 腾讯地图定位
 * 根据定位城市名称请求接口获取当前城市码值
 * 定位/获取码值失败默认北京
 */
import { queryCityId } from "@/api/api_home";
const tencentKey = "GLOBZ-JREW3-VPB3S-3TVIY-LZFXO-I5BWO"; // 定位 key
const geolocation = new qq.maps.Geolocation(tencentKey, "yx-app-m");
const options = { timeout: 5000 };
const Geolocation = callBack => {
  geolocation.getLocation(
    position => {
      queryCityId({
        cityName: position.city
      }).then(res => {
        if (res.code === 0) {
          callBack &&
            callBack({
              cityId: res.data.info,
              cityName: position.city
            });
        } else {
          callBack &&
            callBack({
              cityId: "110100",
              cityName: "北京市"
            });
        }
      });
    },
    err => {
      console.log("Geolocation", err);
      callBack &&
        callBack({
          cityId: "110100",
          cityName: "北京市"
        });
    },
    options
  );
};

export default Geolocation;
