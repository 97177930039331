<!-- 顶部导航 -->
<template>
  <van-nav-bar
    :class="['Header', { hideBorder: hideBorder }]"
    :title="title"
    fixed
    left-arrow
    @click-left="onClickLeft"
  >
    <template #right>
      <slot />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: { type: String },
    hideBorder: { type: Boolean, default: false },
    preRouterName: { type: String, default: "" } // 路由 name
  },
  data() {
    return {
      isShowDownLoad: this.$store.state.isShowDownLoad
    };
  },
  methods: {
    onClickLeft() {
      // 返回指定页面 or 返回上一级
      if (this.preRouterName) {
        this.$router.push({ name: this.preRouterName });
      } else {
        this.$router.back(-1);
      }
    }
  }
};
</script>
<style lang="scss">
.Header {
  height: 0.44rem;
  z-index: 2000 !important;
  .van-nav-bar__content {
    height: 100%;
    .van-nav-bar__title {
      font-size: 0.18rem;
      color: $mainBlack;
    }
    .van-icon {
      color: $mainBlack;
    }
  }
}
.hideBorder {
  &::after {
    content: none !important;
  }
}
</style>
