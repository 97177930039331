import CryptoJS from "crypto-js";
const SecretKey = "1234567812345678"; // 加密 key

export default {
  // AES加密 Hex编码
  AESEncrypt(data) {
    const result = CryptoJS.AES.encrypt(
      data,
      CryptoJS.enc.Utf8.parse(SecretKey),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    return result.ciphertext.toString();
  },
  // AES解密 Hex编码
  // AESDecrypt(hexData) {
  //   const encryptedHexStr = CryptoJS.enc.Hex.parse(hexData);
  //   const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  //   const decryptedData = CryptoJS.AES.decrypt(
  //     encryptedBase64Str,
  //     CryptoJS.enc.Utf8.parse(SecretKey),
  //     {
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7
  //     }
  //   );
  //   return decryptedData.toString(CryptoJS.enc.Utf8);
  // },
  // DES加密
  encryptByDES(message, key) {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.TripleDES.encrypt(message, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  // 处理数组分类
  handleArrayClassify(arr, key) {
    const ls = {};
    const indexList = [];
    arr.forEach(element => {
      const k = key === "year" ? `_${element[key]}` : element[key]; // 防止数字自动排序
      if (ls[k]) {
        ls[k].push(element);
      } else {
        indexList.push(element[key]);
        ls[k] = [element];
      }
    });
    return {
      arr: ls,
      indexList: indexList
    };
  },
  // 校验手机号格式(11位，且开头为1)
  verifyPhone(num) {
    return /^1(\d|\*){10}$/.test(String(num)); // 转为string防止开头为0的数字校验错误
  },
  /**
   * 格式化日期，返回年月日
   * @param {日期} str
   */
  formatDate(str) {
    const date = new Date(str);
    const year = date.getFullYear();
    const montn = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}/${montn}/${day}`;
  },
  // 计算顶部高度
  calcTopHeight() {
    const downloadDom = document.querySelectorAll(".AppDownload")[0];
    const headerDom = document.querySelectorAll(".Header")[0];
    return (
      (downloadDom?.offsetHeight || 0) + (headerDom?.offsetHeight || 0) - 1
    );
  }
};
