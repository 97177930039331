<!-- APP 下载引导，展示规则如下：
1、首次进入，所有页面都展示；若关闭，当日再次进入，所有页面都不展示；
2、次日首次进入展示，规则同上
3、路由 meta 信息中 hideDownload 为 true 的页面任何情况都不展示
-->
<template>
  <div class="AppDownload">
    <div class="ad-black">
      <div class="close" @click="close">
        <img src="@/assets/images/close_transparent.png" alt="" />
      </div>
      <img class="icon" src="@/assets/images/appIcon.png" alt="" />
      <div class="text">
        <p>易鑫金融APP</p>
        <p>低首付 开新车</p>
      </div>
    </div>
    <div class="ad-red" @click="nextPage">打开APP</div>
  </div>
</template>

<script>
export default {
  name: "AppDownload",
  data () {
    return {};
  },
  methods: {
    nextPage () {
      this.$router.push({ name: "Download" });
    },
    close () {
      this.$store.commit("setAPPStatus", false);
      this.$store.commit("setTimeStamp", new Date().getTime());
    }
  }
};
</script>
<style lang="scss" scoped>
.AppDownload {
  height: 0.48rem;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2000;
  .ad-black {
    flex: 1;
    display: flex;
    background: #313236;
    align-items: center;
    .close {
      padding: 0.16rem 0.16rem 0.16rem 0.12rem;
      img {
        width: 0.16rem;
        height: 0.16rem;
      }
    }
    .icon {
      width: 0.36rem;
      height: 0.36rem;
    }
    .text {
      margin-left: 0.1rem;
      p:nth-child(1) {
        font-size: 0.12rem;
        line-height: 0.17rem;
        color: #ffffff;
      }
      p:nth-child(2) {
        font-size: 0.12rem;
        line-height: 0.17rem;
        color: #8d8d91;
      }
    }
  }
  .ad-red {
    width: 1.1rem;
    color: #ffffff;
    background: #ff4848;
    text-align: center;
    line-height: 0.48rem;
  }
}
</style>
