import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isShowDownLoad: true, // 是否展示 App 下载引导
    closeTimeStamp: "", // 记录上一次关闭 app 下载引导的时间
    routerMsg: {}, // 存储路由信息
    position: {}, // 定位信息
    loginInfo: {}, // 用户信息
    carDetailInfo: {} // 存储分期好购车详情信息
  },
  mutations: {
    setRouterMsg(state, info) {
      state.routerMsg = info;
    },
    setAPPStatus(state, info) {
      state.isShowDownLoad = info;
    },
    setTimeStamp(state, info) {
      state.closeTimeStamp = info;
    },
    setpPosition(state, info) {
      state.position = info;
    },
    setLoginInfo(state, info) {
      state.loginInfo = info;
    },
    setCarInfo(state, info) {
      state.carDetailInfo = info;
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
});
