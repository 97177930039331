/**
 * 分期购车相关页面
 */
export const rCar = [
  {
    path: "/brandSelect",
    name: "BrandSelect",
    meta: { title: "选择品牌" },
    component: () => import("@/views/carInstallment/BrandSelect.vue")
  },
  {
    path: "/carList",
    name: "CarList",
    meta: { title: "分期购车", preRouterName: "Home" },
    component: () => import("@/views/carInstallment/CarList.vue")
  },
  {
    path: "/carDetail",
    name: "CarDetail",
    meta: { title: "" },
    component: () => import("@/views/carInstallment/CarDetail.vue")
  },
  {
    path: "/carSelect",
    name: "CarSelect",
    meta: { title: "选择车型" },
    component: () => import("@/views/carInstallment/CarSelect.vue")
  },
  {
    path: "/buyAdvisory",
    name: "BuyAdvisory",
    meta: { title: "购车咨询" },
    component: () => import("@/views/carInstallment/BuyAdvisory.vue")
  }
];
