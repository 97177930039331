/**
 * 我的相关页面
 */

export const rOther = [
  {
    path: "/personal",
    name: "Personal",
    meta: { title: "我的", hideHeader: true },
    component: () => import("@/views/personal/Personal.vue")
  },
  {
    path: "/myInfo",
    name: "MyInfo",
    meta: { title: "个人信息" },
    component: () => import("@/views/personal/MyInfo.vue")
  },
  {
    path: "/modifyNickName",
    name: "ModifyNickName",
    meta: { title: "修改昵称" },
    component: () => import("@/views/personal/ModifyNickName.vue")
  },
  {
    path: "/helpInfo",
    name: "HelpInfo",
    meta: { title: "帮助中心" },
    component: () => import("@/views/personal/HelpInfo.vue")
  },
  {
    path: "/myOrder",
    name: "MyOrder",
    meta: { title: "订单列表" },
    component: () => import("@/views/myOrder/MyOrder.vue")
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    meta: { title: "订单详情" },
    component: () => import("@/views/myOrder/OrderDetail.vue")
  },
  {
    path: "/setting",
    name: "Setting",
    meta: { title: "设置" },
    component: () => import("@/views/personal/Setting.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: { title: "隐私政策" },
    component: () => import("@/views/personal/Privacy.vue")
  },
  {
    path: "/account",
    name: "AccountSecurity",
    meta: { title: "账号安全" },
    component: () => import("@/views/personal/AccountSecurity.vue")
  },
  {
    path: "/cancellation",
    name: "Cancellation",
    meta: { title: "注销须知" },
    component: () => import("@/views/personal/Cancellation.vue")
  },
  {
    path: "/cancellationSecond",
    name: "CancellationSecond",
    meta: { title: "注销账号" },
    component: () => import("@/views/personal/CancellationSecond.vue")
  }
];
